<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectAllArticleCheckbox"
                  :indeterminate="isSelectAllArticleCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedArticles.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'Article')"
                  v-b-modal.modal-update-category-all-article-bulk
                >
                  Change Category
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'Article')"
                  v-b-modal.modal-update-status-all-article-bulk
                >
                  Update Status
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectAllArticleCheckbox"
                :indeterminate="isSelectAllArticleCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedArticles.length"
            >
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Article')"
                v-b-modal.modal-update-category-all-article-bulk
              >
                Change Category
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Article')"
                v-b-modal.modal-update-status-all-article-bulk
              >
                Update Status
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-all-article-category"
                ref="filter_dropdown_all_article"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <article-dropdown-filters
                  :article-category.sync="articleCategoryFilter"
                  :start-date.sync="startDateFilter"
                  :article-cat-options="articleCatOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="startDateFilter || articleCategoryFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="startDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="startDateFilter = ''"
                  >
                    {{ startDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="catVal in articleCategoryFilter"
                  :key="catVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromCategoryFilter(catVal)"
                  >
                    <span class="text-capitalize"><span class="text-capitalize">{{ resolveArticleCatName(catVal) }}</span></span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refArticlesTable"
        class="position-relative event_list min-height-300"
        :items="fetchArticles"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(title)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedArticles.includes(data.item._id)"
              @change="toggleSelectedArticles(data.item._id)"
              @click.native.stop
            >
              <span
                class="text-bold-black align-middle detail-view-id"
              >
                {{ data.item.title }}
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(content)="data">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-wrap"
            v-html="data.item.content"
          />
        </template>

        <template #cell(category)="data">
          <div class="text-wrap">
            <span>{{ data.item.category.name }}</span>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span>{{ data.item.createdBy.name }}</span>
            <br>
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveArticleStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('create', 'Article') || canViewThisAction('show', 'Article') || canViewThisAction('update', 'Article') || canViewThisAction('delete', 'Article')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Article')"
              :to="{ name: 'app-articles-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'Article')"
              :to="{ name: 'app-articles-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'Article')"
              id="toggle-btn"
              v-b-modal.modal-update-status-all-article
              @click="setArticle(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'Article')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalArticles > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalArticles"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-all-article"
      ref="update-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="articleStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-article-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-article-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-status-all-article-bulk"
      ref="update-status-modal-all-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="allArticleBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-article-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-article-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-category-all-article-bulk"
      ref="update-category-modal-all-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Category"
      @ok="updateBulkCategory"
    >
      <b-form @submit.prevent="updateBulkCategory">
        <validation-observer
          ref="allArticleBulkCategoryEditForm"
        >
          <b-form-group
            label="Category*"
            label-for="h-article-category"
          >
            <validation-provider
              #default="{ errors }"
              name="Category"
              vid="category"
              rules="required"
            >
              <v-select
                id="h-article-category"
                v-model="bulkCategory"
                label="name"
                :options="articleCatOptions"
                :reduce="c => c._id"
                :clearable="false"
                placeholder="Select Category"
                @input="bulkCategoryValidation == true ? bulkCategoryValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkCategoryValidation"
                class="text-danger"
              >
                {{ bulkCategoryError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BFormTag,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useAllArticleList from './useAllArticleList'
import articleStoreModule from '../articleStoreModule'
import ArticleDropdownFilters from '../ArticleDropdownFilters.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ArticleDropdownFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      articleID: '',
      title: '',
      status: 'active',
      bulkStatus: 'active',
      bulkCategory: '',
      statusValidation: false,
      statusError: 'Valid status is required',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      bulkCategoryValidation: false,
      bulkCategoryError: 'Valid category is required',
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      articleCatOptions: [],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },
  beforeMount() {
    this.$http.get('customer/articles/respond-with/category-options')
      .then(response => {
        this.articleCatOptions = response.data.categoryOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_article.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_article.show(true)
    },
    removeFromCategoryFilter(item) {
      const index = this.articleCategoryFilter.indexOf(item)
      if (index > -1) {
        this.articleCategoryFilter.splice(index, 1)
      }
    },
    resolveArticleCatName(id) {
      const category = this.articleCatOptions.find(o => o._id === id)
      if (category) {
        return category.name || ''
      }
      return ''
    },
    clearAllFilters() {
      this.startDateFilter = ''
      this.articleCategoryFilter = []
    },
    setArticle(item) {
      this.articleID = item._id
      this.status = item.status
      this.title = item.title
    },
    updateBulkStatus() {
      this.$refs.allArticleBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedArticles.length; i++) {
            formData.append('articles[]', this.selectedArticles[i])
          }

          this.$http.patch('customer/articles/action/bulk/status/update', formData)
            .then(() => {
              this.refetchData()
              this.$emit('emitCountArticle')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected articles have been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    updateBulkCategory() {
      this.$refs.allArticleBulkCategoryEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('category', this.bulkCategory)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedArticles.length; i++) {
            formData.append('articles[]', this.selectedArticles[i])
          }

          this.$http.patch('customer/articles/action/bulk/category/update', formData)
            .then(() => {
              this.refetchData()
              this.$swal({
                title: 'Category Updated!',
                html: 'The category for selected articles have been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkCategory') {
                    this.bulkCategoryError = validationError.msg
                    this.bulkCategoryValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitForm() {
      this.$refs.articleStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`customer/articles/${this.articleID}/status/update`, formData)
            .then(() => {
              this.refetchData()
              this.$emit('emitCountArticle')
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>‘${this.title}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Delete this Article?',
        html: 'Are you sure you want to delete this article?<br/>This cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete-yellow.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, delete',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.toDeletedID = id
            await this.deleteArticle()
            this.$emit('emitCountArticle')
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const ARTICLE_APP_STORE_MODULE_NAME = 'app-custom-all-articles'

    // Register module
    if (!store.hasModule(ARTICLE_APP_STORE_MODULE_NAME)) store.registerModule(ARTICLE_APP_STORE_MODULE_NAME, articleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ARTICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ARTICLE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchArticles,
      tableColumns,
      perPage,
      currentPage,
      totalArticles,
      articleData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refArticlesTable,
      refetchData,

      toDeletedID,
      deleteArticle,

      // UI
      resolveArticleStatusVariant,

      // Extra Filters
      articleCategoryFilter,
      startDateFilter,
    } = useAllArticleList()

    const selectedArticles = ref([])
    const toggleSelectedArticles = articleID => {
      const articleIndex = selectedArticles.value.indexOf(articleID)

      if (articleIndex === -1) selectedArticles.value.push(articleID)
      else selectedArticles.value.splice(articleIndex, 1)
    }
    const selectAllArticleCheckbox = computed(() => articleData.value.length && (articleData.value.length === selectedArticles.value.length))
    const isSelectAllArticleCheckboxIndeterminate = computed(() => Boolean(selectedArticles.value.length) && articleData.value.length !== selectedArticles.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedArticles.value = val ? articleData.value.map(book => book._id) : []
    }

    return {

      // Sidebar

      fetchArticles,
      tableColumns,
      perPage,
      currentPage,
      totalArticles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refArticlesTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteArticle,
      articleData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveArticleStatusVariant,

      statusOptions,

      // Extra Filters
      articleCategoryFilter,
      startDateFilter,

      selectAllArticleCheckbox,
      isSelectAllArticleCheckboxIndeterminate,
      selectedArticles,
      toggleSelectedArticles,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
